*{
    padding:0;
    margin:0;
   list-style: none;
   text-decoration: none;
  }

  .container{
      display: flex;
      top: 0;
      height: 100%;
      width: 100vw;
      /* overflow: hidden; */
  }

 
