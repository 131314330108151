.contact-container{
    width: 87vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    /* flex-wrap: wrap; */

}
.left-contact{
    height:70%;
    width: 30%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.right-contact{
    /* margin-top: 4rem; */
    width:50%;
    height:80%;
    background: linear-gradient(270deg, #FFB147 5.74%, #FF6C63 50.64%, #B86ADF 92.05%);

}
.form-heading p{
color: #FFF;
font-family: IBM Plex Sans;
font-size: 2.5rem;
font-style: normal;
font-weight: 700;
line-height: 3rem; /* 120% */
text-transform: uppercase;
}
.form-1{
    display: flex;
    margin-top: 4rem;
    gap:4rem
 
}
.inp{
    outline: none;
    background: none;
    border: none;
    color: #FFF;
    font-size: 1.3rem;
    border-bottom: 2px solid white;
    width:100%;
    height: 3rem;
}
.inp::placeholder{
color: #FFF;
font-family: IBM Plex Sans;
font-size: 1.3rem;
font-style: normal;
font-weight: 500;
line-height: 1.2rem; /* 150% */
text-transform: uppercase;
}

.form-2{
    margin-top: 2rem;
}
.inp-3{
    color: #FFF;
    outline: none;
    background: none;
    border: none;
    font-size: 1.3rem;
    border-bottom: 2px solid white;
    width:100%;
    height: auto;

    resize: none;
    overflow-y: hidden;
    /* height: 6rem; */
}
.form-content{
    margin: 4rem;
}
.submit-btn-container{
    margin-top: 1.5rem;
    height: 2.5rem;
    width: 11rem;
}
.submit-btn{
    height: 2.5rem;
    width: 11rem;
color: #FFF;
background:#141313;
font-family: IBM Plex Sans;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 1.5rem; /* 133.333% */
display:flex;
/* height: 3.5rem; */
padding: 0.3rem 1.3rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
cursor: pointer;
}
.contact-head{
color: #141313;
font-family: IBM Plex Sans;
font-size: 1.125rem;
font-style: italic;
font-weight: 600;
line-height: 1.5rem; /* 133.333% */
}
.reach-out-me{
color: #141313;
font-family: IBM Plex Sans;
font-size: 3rem;
font-style: normal;
font-weight:bold;
line-height: 3.5rem; /* 116.667% */
text-transform: uppercase;
margin-top: 0.5rem;
}
.location-txt{
    margin-top: 1rem;
color: #141313;
font-family: IBM Plex Sans;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
letter-spacing: -0.03rem;
}
.connections{
    color: #141313;
    font-family: IBM Plex Sans;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem; /* 125% */
    margin-top: 2rem;
}
.s-media{
    display:flex;
    justify-content: space-between;
    margin-top:auto;
}
.s-media p{
color: #141313;
font-family: IBM Plex Sans;
font-size: 0.8rem;
font-style: normal;
font-weight:bold;
line-height: 1.5rem; /* 150% */
letter-spacing: -0.03rem;
text-transform: uppercase;
}
.loader {
    width: 35px;
    height: 35px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  } 
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #FF3D00;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  @media (max-width:1207px) {
    
    .contact-container{
        /* height: 100vh; */
    }
  }
  @media (max-width:934px) {

    .contact-container{
        flex-direction: column;
        justify-content: center;
        padding: 4rem;
        height: 100%;
    }


    .left-contact , .right-contact{
        width: 60%;
    }
  }
  @media (max-width:770px) {
    
    .left-contact , .right-contact{
        width: 90%;
        margin: auto;
    }
    .contact-container{
        padding: 3rem 0;
    }

    .submit-btn-container{
        height: 3.5rem;
        width: 100%;
    }
    .submit-btn{
        height: 100%;
        width: 100%;
        padding:0;
    }
  }

  @media (max-width:580px) {
    .form-content{
        margin: 2rem;
    }
  }
 
