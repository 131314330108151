.icons-container{
    height: 1.5rem;
    width: 1.5rem;
   font-size: 1.5rem;
   margin: 0.5rem;
}
/* 
.ss{
    height: 1rem;
    width: 1rem;
} */
.sidebars{
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    /* border: 1px solid red; */
    /* height: 100%; */
    width: 5rem;
    background-color: #141313;
    border-radius: 1rem;
    display: none;
    min-width:4rem;
}

.contents{
    font-family: 'IBM Plex Sans', sans-serif;
    color: white;
    box-sizing: border-box;
    height:100%;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.logos{
    /* padding-top: 2rem; */
    /* margin-left: 3rem; */
    width: 40%;
    color: white;
} 
.menus{
    /* margin-left: 3rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* width: 40%; */
    height: 100%;
    /* color: white; */
    /* align-items: center; */
    /* justify-content:space-evenly; */
    gap:2.5rem;
}
.menus li a{
    color: white;
    position: relative;
}

.menus li a::after{
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    border-color: coral;
    border-radius: 5px;
    margin: auto;
    display: block;
    bottom: -10px;
    background-color: coral;
}

.menus li a:hover::after{
    width: 100%;
    transition: width 0.3s linear;
}

.socialss{
    /* margin-left: 3rem; */
    display: flex;
    flex-direction: column;
    height: 25%;
    width: 40%;
    margin-top: 2rem;
    /* align-items: center; */
    justify-content:space-between;

    /* border: 1px solid red;  */
}

.copyrights{
    width: 100%;
    height: 3rem;
    margin-top: 3rem;
    font-size: 0.7rem;
    /* margin-left: 3rem; */
}