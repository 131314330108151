@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Rajdhani:wght@300;400&family=Roboto+Mono&display=swap');

.homepage{
    display: flex;
    top: 0;
    height:100vh;
    width: 87vw;
}
.sidebar{
    box-sizing: border-box;
    top: 0; 
    left: 0; 
    /* border: 1px solid red; */
    height: 100vh;
    width: 13vw;
    background-color: #141313;
}

.content{
    font-family: 'IBM Plex Sans', sans-serif;
    color: white;
    box-sizing: border-box;
    height:100%;
    width:100%;
    padding: 3rem;
}
.logo{
    /* padding-top: 2rem; */
    /* margin-left: 3rem; */
    width: 40%;
    color: white;
} 
.menu{
    /* margin-left: 3rem; */
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 100%;
    gap: 2rem;
    /* height: 40%; */
    /* color: white; */
    /* align-items: center; */
    justify-content: space-evenly;
}
.menu li a{
    color: white;
    position: relative;
}

.menu li a::after{
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    border-color: coral;
    border-radius: 5px;
    margin: auto;
    display: block;
    bottom: -10px;
    background-color: coral;
}

.menu li a:hover::after{
    width: 100%;
    transition: width 0.3s linear;
}

.socials{
    /* margin-left: 3rem; */
    display: flex;
    flex-direction: column;
    height: 25%;
    width: 40%;
    margin-top: 2rem;
    /* align-items: center; */
    justify-content:space-between;

    /* border: 1px solid red;  */
}
.s{
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    /* background-color:white; */
    /* background: linear-gradient(45deg, #FAAD4F 14.61%, #DD2A7B 39.38%, #9537B0 58.49%, #515BD4 85.39%);; */
}
.copyright{
    width: 100%;
    height: 3rem;
    font-size: 0.7rem;
    /* margin-left: 3rem; */
}
.hero{
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;

}
.content2{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 2rem; */
}
.vec{
    position: absolute;
    top: 8rem;
    left: 5rem;
    z-index: 1;
    animation: vecanimate 4s ease-in infinite;
}
@keyframes vecanimate {
    50%{
        left:2rem;
        top:7rem;
        /* rotate: 40deg; */
        scale: 1.2;
    }
}
.head-txt{
    z-index: 2;
   /* border: 2px solid red; */
   /* padding-left: 8rem; */
   width: 35%;
   height: 80%;
   margin-left:8rem;
   padding-top: 8rem;
}
.head-p{
color: #141313;
font-family: IBM Plex Sans;
font-size: 5rem;
font-style: normal;
font-weight: 500;
line-height: 5rem; /* 88.235% */
text-transform: uppercase;
}

.head-p span{
color: #141313;
font-family: IBM Plex Sans;
font-size: 5rem;
font-style: normal;
font-weight: 800;
line-height: 5rem;
text-transform: uppercase;
}
.details-p{
color: #141313;
font-family: IBM Plex Sans;
font-size: 1.6rem;
font-style: italic;
font-weight: 700;
line-height: 2rem; /* 100% */
letter-spacing: -0.06rem;
}
.details-p span{
    margin-left: 0.8rem;
color: #141313;
font-family: IBM Plex Sans;
font-size: 1.6rem;
font-style: normal;
font-weight: 400;
line-height: 2rem;
letter-spacing: -0.06rem;
}
.txt-button{
    font-family: IBM Plex Sans;
    color: white;
    background-color:#141313;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 16rem;
    margin-left: 0.3rem;
    margin-top: 1.5rem;
}
.txt-button img{
    margin-left: 0.7rem;
    height: 1.1rem;
    width: 1.1rem;
}
.cntct{
display: flex;
align-items: flex-start;
flex-direction: column;
font-family: IBM Plex Sans;
margin-top: 2rem;
font-style: italic;
font-weight: 700;
line-height: 1rem;
gap: 0.5rem;
}
.communication{
    display: flex;
    align-items: center;
    justify-content: center;
}
.communication img{
    padding: 0.4rem;
    border-radius: 100%;
    background-color:rgba(20, 19, 19, 0.05);;
}
.image{
    /* border: 2px solid blue; */
    position: relative;
    width: 65%;
    height: 100%;
    /* display: flex;
    align-items:end; */
    /* width:auto;
    height: auto;  */
    /* overflow: hidden; */
    /* right: 0; */
}
.photo-container{
/* border: 1px solid green; */
width: 100%;
height: 100%;   
position: relative;
}
.photo-container img{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    /* left: 0; */
    object-fit: cover;
    animation: animate 7s linear infinite;
}
@keyframes animate {
    25%{
        right: -2rem;
    }
    50%{
        right: -1rem;
        top: 1rem;
        /* scale: 1.2; */
    }
    75%{
      bottom: 1rem;
    }
}

/* @media (max-width:1129px) {

    /* html{
        font-size: 55%;
    } */
    /* .sidebar
    {
        display:none;
    } */
    /* .photo-container{
        display: none;
    }
     */
    
     /* .hero{
        display: none;
        flex-direction: column;
     } */
 
